import { createStore } from "vuex";
import { Auth } from "aws-amplify";
import Customer from "@/models/Customer";

export default createStore({
  state: {
    is_loading: false,
    is_logged_in: false,
    show_video: false,
    customer: null as Customer | null,
  },

  mutations: {
    setIsLoading(state, value) {
      // console.log(`setIsLoading(${value})`);
      state.is_loading = value;
    },

    setIsLoggedIn(state, value) {
      // console.log(`setIsLoggedIn(${value})`);
      state.is_logged_in = value;
    },

    setShowVideo(state, value) {
      // console.log(`setIsLoggedIn(${value})`);
      state.show_video = value;
    },

    setCustomer(state, value) {
      // console.log(`setCustomer(${value})`);
      state.customer = value;
    },
  },

  actions: {
    async signIn({ commit }) {
      const cognitoUser = await Auth.currentUserInfo();

      // console.log(cognitoUser);

      const customer = await Customer.get(cognitoUser.attributes.sub);
      // console.log(customer);
      commit("setCustomer", customer);

      // console.log(cognitoUser);
      commit("setIsLoggedIn", !!cognitoUser);
    },

    async signOut({ commit }) {
      await Auth.signOut();
      commit("setIsLoggedIn", false);
    },

    async refreshUser({ commit }) {
      console.log("Store.refreshUser()");
      const cognitoUser = await Auth.currentUserInfo();

      // console.log(cognitoUser.attributes.sub);

      const customer = await Customer.get(cognitoUser.attributes.sub);
      // console.log(customer);
      commit("setCustomer", customer);

      // console.log(cognitoUser);
      commit("setIsLoggedIn", !!cognitoUser);

      if(!customer) {
        commit("setShowVideo", false);
      } else {
        const shown = sessionStorage.getItem('shown_video');
        if(!shown) {
          commit("setShowVideo", true);
        } else {
          commit("setShowVideo", shown !== 'true');
        }
      }
    },

    setLoading({ commit }, is_loading: boolean) {
      commit("setIsLoading", is_loading);
    },

    markShownVideo({ commit }) {
      sessionStorage.setItem('shown_video', 'true');
      commit("setShowVideo", false);
    }
  },
});
